import { Action } from 'redux'
import { put, takeLatest, select } from 'redux-saga/effects'
import * as Eff from 'redux-saga/effects' // tslint:disable-line:no-duplicate-imports
import toastr from 'toastr'

import Logger from '@Utils/Logger'

import {
  loadProjectVersions,
  fetchProjectVersionsSuccess,
  fetchProjectVersionsFailure,
  updateProjectVersionsSuccess,
  updateProjectVersionsFailure,
  updateProjectVersions,
} from './actions'

import {
  ProjectVersionActionTypes,
  IUpdateProjectVersionsPayload,
  IFetchProjectVersionsSuccessResponse,
  IUpdateProjectVersionsSuccessResponse,
  IUpdateProjectVersionsApiCallPayload,
  IFetchProjectVersionsApiCallPayload,
  IRateSubtitlePayload,
} from './types'

import {
  IAction,
} from '@Root/types'

import {
  LabelActionTypes,
} from '@SiteModules/Label/types'

import {
  ProjectVersionSettingsActionTypes,
} from '@SitePages/ProjectVersionSettings/types'

import {
  DeleteProjectVersionActionTypes,
} from '@SiteContainers/DeleteProjectVersionPopup/types'

import * as API from './api'

const call: any = Eff.call
const DEFAULT_PER_PAGE = 8

function* fetchProjectVersionsSaga(action: Action): Generator<any, any, any> {
  try {
    yield put(loadProjectVersions())
    const state = yield select()
    const payloadForApiCall: IFetchProjectVersionsApiCallPayload = {
      selectedLabels: state.labels.selectedLabels,
      page: state.projectVersionsList.currentPage,
      perPage: DEFAULT_PER_PAGE
    }

    const response: IFetchProjectVersionsSuccessResponse = yield call(API.fetchProjectVersions, payloadForApiCall)
    const { data, included, totalCount, totalPages } = response.data

    yield put(fetchProjectVersionsSuccess(data, included, totalCount, totalPages))
  } catch (e) {
    Logger.error(e, 'Project versions could not be loaded')
    yield put(fetchProjectVersionsFailure())
  }
}

function* updateProjectVersionsSaga(action: IAction<IUpdateProjectVersionsPayload>): Generator<any, any, any> {
  try {
    const state = yield select()
    const payload = action.payload as IUpdateProjectVersionsPayload
    const payloadForApiCall: IUpdateProjectVersionsApiCallPayload = {
      ...payload,
      selectedLabels: state.labels.selectedLabels
    }
    const response: IUpdateProjectVersionsSuccessResponse = yield call(API.updateProjectVersions, payloadForApiCall)
    const payloadResponse = response.data
    yield put(updateProjectVersionsSuccess(payloadResponse))
  } catch (e) {
    Logger.error(e, 'Videos could not be updated')
    yield put(updateProjectVersionsFailure())
  }
}

function* rateSubtitleSaga(action: IAction<IRateSubtitlePayload>): Generator<any, any, any> {
  try {
    yield call(API.rateSubtitle, action.payload)
    toastr.success('We have received your evaluation.', 'Thank you!')

    const payload: IUpdateProjectVersionsPayload = { subtitlesToUpdateIds: [parseInt(action.payload!.subtitleId, 10)] }
    yield put(updateProjectVersions(payload))
  } catch(e) {
    Logger.error(e)
  }
}

function* projectVersionsListSagas() {
  yield takeLatest(ProjectVersionActionTypes.FETCH_PROJECT_VERSIONS, fetchProjectVersionsSaga)
  yield takeLatest(ProjectVersionActionTypes.UPDATE_PROJECT_VERSIONS, updateProjectVersionsSaga)
  yield takeLatest(LabelActionTypes.CHANGE_SELECTED_LABELS, fetchProjectVersionsSaga)
  yield takeLatest(LabelActionTypes.RESET_LABEL_SELECTION, fetchProjectVersionsSaga)
  yield takeLatest(LabelActionTypes.CREATE_PROJECT_VERSION_LABELS_SUCCESS, fetchProjectVersionsSaga)
  yield takeLatest(LabelActionTypes.DELETE_PROJECT_VERSION_LABELS_SUCCESS, fetchProjectVersionsSaga)
  yield takeLatest(ProjectVersionSettingsActionTypes.SUBMIT_PROJECT_VERSION_UPDATE_SUCCESS, fetchProjectVersionsSaga)
  yield takeLatest(ProjectVersionActionTypes.INCREASE_PAGE_COUNT, fetchProjectVersionsSaga)
  yield takeLatest(ProjectVersionActionTypes.DECREASE_PAGE_COUNT, fetchProjectVersionsSaga)
  yield takeLatest(ProjectVersionActionTypes.RATE_SUBTITLE, rateSubtitleSaga)
  yield takeLatest(DeleteProjectVersionActionTypes.DELETE_PROJECT_VERSION_SUCCESS, fetchProjectVersionsSaga)
}

export default projectVersionsListSagas
