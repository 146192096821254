import React, { useRef, useState, useEffect } from 'react'
import { Loadable } from '@Root/types'
import { useForm } from 'react-hook-form'
import uniq from 'lodash/uniq'
import without from 'lodash/without'
import toastr from 'toastr'

import MainSettings from '@SiteComponents/ProjectVersionSettingsForm/MainSettings'
import EmailInput from './EmailInput'
import ConfirmationButton from '@SiteComponents/ProjectVersionSettingsForm/ConfirmationButton'
import ButtonNewV2 from '@SiteComponents/ButtonNewV2'
import Return from '@Images/return_from_pv_settings.svg'
// import NabshowLogo from '@Images/nabshow_logo.svg'
// import AfcLogo from '@Images/afc_logo.png'
import IbcLogo from '@Images/ibc_logo.png'
import ConfirmationArrow from '@Images/confirmation_arrow.svg'
import SalesTeam from '@Images/sales_team.png'

import {
  ISubmitDemoProjectVersionSettingsPayload,
} from '@SiteModules/Demo/types'

import '@checksub_team/react-widgets/dist/css/react-widgets.css'
import '@Root/config/dropdowns.scss'
import '@SiteComponents/ProjectVersionSettingsForm/ProjectVersionSettingsForm.scss'
import './DemoProjectVersionSettingsForm.scss'

interface DemoProjectVersionSettingsFormProps extends Loadable {
  readonly videoUrl: string
  readonly languagesForVideos: string[]
  readonly translationLanguages: string[]
  readonly hasLoaded: boolean
  readonly submitted: boolean
  readonly needsDownload: boolean
  submitDemoProjectVersionSettings(payload: ISubmitDemoProjectVersionSettingsPayload): void
  setSubmitted(): void
}

const DEFAULT_MAX_LANGUAGES_LIMIT = 3
const DEFAULT_PROJECT_VERSION_LANGUAGE = 'English'
const DEFAULT_SUBTITLE_LANGUAGES = ['English', 'French', 'Spanish']
const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{1,63})+$/ // As used in site/pages/UsersSignUp/SignUpFormStep1.tsx

type TFormData = {
  subtitleLanguages: string
  projectVersionLanguage: string
}

function usePrevious(value: any) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef()

  // Store current value in ref
  useEffect(() => {
    ref.current = value
  }, [value]) // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current
}

const DemoProjectVersionSettingsForm: React.FC<DemoProjectVersionSettingsFormProps> = props => {
  const {
    videoUrl,
    hasLoaded,
    needsDownload,
  } = props

  const { register, getValues, errors } = useForm<TFormData>()

  const [projectVersionLanguage, setProjectVersionLanguage] = useState(DEFAULT_PROJECT_VERSION_LANGUAGE)
  const [subtitleLanguages, setSubtitleLanguages] = useState<string[]>(DEFAULT_SUBTITLE_LANGUAGES)
  const [languagesCorrectlySelected, setLanguagesCorrectlySelected] = useState(true)
  const [projectType, setProjectType] = useState('Dubbing with lip-sync')
  const [email, setEmail] = useState('')
  const [isReadyForEmail, setIsReadyForEmail] = useState(false)
  const prevProjectVersionLanguage = usePrevious(projectVersionLanguage)

  let isLanguageLimitReached = false

  isLanguageLimitReached = DEFAULT_MAX_LANGUAGES_LIMIT < subtitleLanguages.length

  const isDubbingProject = projectType === 'Dubbing with lip-sync'
  const numberOfNewTranslationSubtitles = subtitleLanguages.length - 1

  const areThereNewTranslationSubtitlesPresent = numberOfNewTranslationSubtitles > 0

  useEffect(() => {
    if (!projectVersionLanguage) { return }

    addToSubtitles()
  }, [projectVersionLanguage])

  useEffect(() => {
    const areNewSubtitlesAdded = subtitleLanguages.length
    const shouldShowSubscriptionWarning = isLanguageLimitReached && areNewSubtitlesAdded

    if (shouldShowSubscriptionWarning) {
      toastr.error(
        `${DEFAULT_MAX_LANGUAGES_LIMIT} subtitling languages are allowed.`,
        'Subtitles limit reached',
      )
    }

    if (!areThereNewTranslationSubtitlesPresent && isDubbingProject) {
      setProjectType('Subtitles')
    }
  }, [subtitleLanguages])

  useEffect(() => {
    setLanguagesCorrectlySelected(!!projectVersionLanguage && subtitleLanguages.length > 0)
  }, [projectVersionLanguage, subtitleLanguages])

  const addToSubtitles = () => {
    const languages = getValues('subtitleLanguages') || '[]'
    let currentSubtitleLanguages = JSON.parse(languages)

    currentSubtitleLanguages.unshift(projectVersionLanguage)
    currentSubtitleLanguages = uniq(without(currentSubtitleLanguages, prevProjectVersionLanguage))
    setSubtitleLanguages(currentSubtitleLanguages)
  }

  const updateProjectVersion = () => {
    const dubbingSettings = { // Settings for "Dubbing with lip-sync" since it is the only dubbing project type at the moment
      adaptTranslation: true,
      syncLips: true
    }

    const payload: ISubmitDemoProjectVersionSettingsPayload = {
      videoUrl,
      email,
      projectVersionLanguage,
      subtitleLanguages,
      needsDownload,
      dubbing: isDubbingProject,
      ...(isDubbingProject ? dubbingSettings : {}),
    }
    props.submitDemoProjectVersionSettings(payload)
    props.setSubmitted()
    setIsReadyForEmail(false)
  }

  const goToUploadPageOrPreviousStep = () => {
    if (isReadyForEmail) {
      setIsReadyForEmail(false)
    } else {
      window.location.href = '/demo/upload'
    }
  }

  const renderMainSettings = () => {
    const { languagesForVideos, translationLanguages } = props
    const shouldAllowDubbingProject = areThereNewTranslationSubtitlesPresent
    const projectTypeOptions = shouldAllowDubbingProject ? ['Subtitles', 'Dubbing with lip-sync'] : ['Subtitles']

    return (
      <MainSettings
        errors={errors}
        register={register}
        projectVersionLanguage={projectVersionLanguage}
        changeSubtitleLanguages={setSubtitleLanguages}
        isTranslatable
        subtitleLanguages={subtitleLanguages}
        languagesForVideos={languagesForVideos}
        changeProjectVersionLanguage={setProjectVersionLanguage}
        initialProjectVersionLanguage={''}
        translationLanguages={translationLanguages}
        disabledSubtitleLanguages={[projectVersionLanguage]}
        hasLoaded={hasLoaded}
        projectType={projectType}
        changeProjectType={setProjectType}
        projectTypeOptions={projectTypeOptions}
      />
    )
  }

  const renderConfirmationButtonSettings = () => {
    const {
      showSpinner,
      disableButton,
    } = props

    const disableConfirmationButton =
      disableButton ||
      !languagesCorrectlySelected

    return (
      <ConfirmationButton
        showSpinner={showSpinner}
        disableButton={disableConfirmationButton}
        projectVersionLanguage={projectVersionLanguage}
        isLanguageLimitReached={isLanguageLimitReached}
        onlyNewSubtitleLanguages={subtitleLanguages}
        isPaidBySubscription
        shouldRenderLinkToSubscription={false}
        shouldDisableDebitDropdown={false}
        icon={disableConfirmationButton ? undefined : <ConfirmationArrow/>}
        iconRight
      />
    )
  }

  const renderConfirmationButtonEmail = () => {
    const emailCorrect = EMAIL_REGEX.test(email)
    const disableConfirmationButton = props.submitted || !emailCorrect

    return (
      <div className="DemoProjectVersionSettingsForm__confirmation-button-email">
        <ButtonNewV2
          type="submit"
          styleType="brand-primary"
          size="large"
          caption="Generate my video"
          showSpinner={false}
          disabled={disableConfirmationButton}
        />
      </div>
    )
  }

  const submit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    isReadyForEmail ? updateProjectVersion() : setIsReadyForEmail(true)
  }

  const renderBackButton = () => {
    return (
      <div
        className="ProjectVersionSettingsForm__return"
        onClick= {goToUploadPageOrPreviousStep}
      >
        <Return />
      </div>
    )
  }

  const renderSettingsForm = () => {
    return (
      <React.Fragment>
        <div className="ProjectVersionSettingsForm__title">
          Fine-tune your project
        </div>
        {renderMainSettings()}
        {renderConfirmationButtonSettings()}
      </React.Fragment>
    )
  }

  const renderEmailForm = () => {
    return (
      <React.Fragment>
        <div className="ProjectVersionSettingsForm__title">
          You're almost there!
        </div>
        <EmailInput
          email={email}
          setEmail={setEmail}
        />
        {renderConfirmationButtonEmail()}
      </React.Fragment>
    )
  }

  const renderConfirmationPage = () => {
    return (
      <React.Fragment>
        <div className="ProjectVersionSettingsForm__title DemoProjectVersionSettingsForm__title-confirmation">
          Your translation is<br />being created...
        </div>
        <div className="DemoProjectVersionSettingsForm__subtitle">
          Once finalized, you will receive it by email.
        </div>
        <div className="DemoProjectVersionSettingsForm__meeting">
          <img src={SalesTeam}/>
          <a
            href="https://meetings-eu1.hubspot.com/florian-stegre/show-event"
            className="DemoProjectVersionSettingsForm__book-meeting"
            target="_blank"
          >
            Book a meeting with our Sales Team
          </a>
        </div>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      {renderBackButton()}
      <form className="ProjectVersionSettingsForm DemoProjectVersionSettingsForm" onSubmit={submit}>
        <div className="ProjectVersionSettingsForm__left">
          <div className="ProjectVersionSettingsForm__left-wrapper">
            <div className="ProjectVersionSettingsForm__left-top">
              {/* <NabshowLogo className="DemoProjectVersionSettingsForm__nabshow-logo"/> */}
              {/* <img src={AfcLogo} className="DemoProjectVersionSettingsForm__afc-logo"/>*/}
              <img src={IbcLogo} className="DemoProjectVersionSettingsForm__ibc-logo"/>
              {!isReadyForEmail && !props.submitted && renderSettingsForm()}
              {isReadyForEmail && renderEmailForm()}
              {props.submitted && renderConfirmationPage()}
            </div>
          </div>
        </div>
      </form>
    </React.Fragment>
  )
}

export default DemoProjectVersionSettingsForm
