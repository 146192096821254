import { TActionTypes } from '@Root/types'

export const ProjectVersionActionTypes = {
  LOAD_PROJECT_VERSIONS: '@@projectVersionsList/LOAD_PROJECT_VERSIONS',
  FETCH_PROJECT_VERSIONS: '@@projectVersionsList/FETCH_PROJECT_VERSIONS',
  FETCH_PROJECT_VERSIONS_SUCCESS: '@@projectVersionsList/FETCH_PROJECT_VERSIONS_SUCCESS',
  FETCH_PROJECT_VERSIONS_FAILURE: '@@projectVersionsList/FETCH_PROJECT_VERSIONS_FAILURE',
  UPDATE_PROJECT_VERSIONS: '@@projectVersionsList/UPDATE_PROJECT_VERSIONS',
  UPDATE_PROJECT_VERSIONS_SUCCESS: '@@projectVersionsList/UPDATE_PROJECT_VERSIONS_SUCCESS',
  UPDATE_PROJECT_VERSIONS_FAILURE: '@@projectVersionsList/UPDATE_PROJECT_VERSIONS_FAILURE',
  CLOSE_POPUP: '@@projectVersionsList/CLOSE_POPUP',
  OPEN_EXTERNAL_TOKEN_POPUP: '@@projectVersionsList/OPEN_EXTERNAL_TOKEN_POPUP',
  OPEN_EXPORTATION_POPUP: '@@projectVersionsList/OPEN_EXPORTATION_POPUP',
  OPEN_RATE_SUBTITLE_POPUP: '@@projectVersionsList/OPEN_RATE_SUBTITLE_POPUP',
  OPEN_IMPORT_SRT_POPUP: '@@projectVersionsList/OPEN_IMPORT_SRT_POPUP',
  OPEN_DELETE_PROJECT_VERSION_POPUP: '@@projectVersionsList/OPEN_DELETE_PROJECT_VERSION_POPUP',
  CHANGE_SELECTED_PROJECT_VERSIONS: '@@projectVersionsList/CHANGE_SELECTED_PROJECT_VERSIONS',
  INCREASE_PAGE_COUNT: '@@projectVersionsList/INCREASE_PAGE_COUNT',
  DECREASE_PAGE_COUNT: '@@projectVersionsList/DECREASE_PAGE_COUNT',
  RATE_SUBTITLE: '@@projectVersionsList/RATE_SUBTITLE'
}

export interface IProjectVersionsListState {
  readonly projectVersions: IProjectVersion[]
  readonly loading: boolean
  readonly subtitleId: number
  readonly subtitleIds: number[]
  readonly currentPage: number
  readonly totalCount: number
  readonly totalPages: number
  readonly subtitles: ISubtitle[]
  readonly editingProjectVersionIds: string[]
  readonly selectedProjectVersions: string[]
  readonly openImportSrtPopup: boolean
  readonly openDeleteProjectVersionPopup: boolean
  readonly openExportationPopup: boolean
  readonly openRateSubtitlePopup: boolean
  readonly openExternalTokenPopup: boolean
  readonly subtitleStlFileUrl: string | null
  readonly subtitleHasPaidExportation: boolean
  readonly subtitleHasSpeakers: boolean
  readonly editingVideoBelongsToManualProject: boolean
}

export interface IProjectVersion {
  readonly id: string
  readonly type: 'project_version'
  readonly attributes: IProjectVersionAttributes
  readonly relationships: IProjectVersionRelationships
}

export interface IProjectVersionAttributes {
  readonly title: string
  readonly status: string
  readonly duration: string
  readonly language: string
  readonly createdAt: string
  readonly projectId: string
  readonly thumbnailUrl: string
  readonly hasBeenCaptionedForFree: boolean
  readonly hasAnySubscriptionSubtitles: boolean
  readonly belongsToManualProject: boolean
  readonly belongsToTtsProject: boolean
  readonly isDisabled: boolean
  readonly hasAssets: boolean,
  readonly isShortened: boolean,
}

export type TProjectVersionDependency = ISubtitle

export interface IProjectVersionRelationships {
  readonly subtitles: {
    readonly data: ISubtitleReference[]
  }
}

export interface IOpenExternalToken {
  readonly payload: {
    readonly subtitleId: number
  }
}

export interface IOpenExportationAction {
  readonly type: string
  readonly payload: IOpenExportationPayload
}

export interface IOpenExportationPayload {
  readonly subtitleIds: number[]
}

export interface IOpenRateSubtitle {
  readonly payload: IOpenRateSubtitlePayload
}

export interface IOpenRateSubtitlePayload {
  readonly subtitleId: number
}

export interface IOpenImportSrtAction {
  readonly type: string
  readonly payload: IOpenImportSrtPayload
}

export interface IOpenImportSrtPayload {
  readonly projectVersionId: string
  readonly subtitleId: number
}

export interface IChangeSelectedProjectVersionsAction {
  readonly type: string
  readonly payload: IChangeSelectedProjectVersionsPayload
}

export interface IChangeSelectedProjectVersionsPayload {
  readonly projectVersionId: string
  readonly selectProjectVersion: boolean
}

export interface IUpdateProjectVersionsAction {
  readonly type: string
  readonly payload: IUpdateProjectVersionsPayload
}

export interface IUpdateProjectVersionsPayload {
  readonly subtitlesToUpdateIds: number[]
}

export interface IUpdateProjectVersionsApiCallPayload {
  readonly subtitlesToUpdateIds: number[]
  readonly selectedLabels: number[]
}

export interface IUpdateProjectVersionsFailureAction {
  readonly type: string
  readonly error: string
}

export interface IUpdateProjectVersionsSuccessAction {
  readonly type: string
  readonly payload: IUPdateProjectVersionSuccessResponsePayload
}

export interface IUpdateProjectVersionsSuccessResponse {
  readonly data: IUPdateProjectVersionSuccessResponsePayload
  readonly config: object
  readonly headers: object
  readonly request: object
  readonly status: 200
  readonly statusText: string
}

export interface IUPdateProjectVersionSuccessResponsePayload {
  readonly subtitles: {
    readonly data: ISubtitle[]
  }
  readonly projectVersions: {
    readonly data: IProjectVersion[]
  }
}

export interface ISubtitle {
  readonly id: string
  readonly type: 'subtitle'
  readonly attributes: {
    readonly type: string
    readonly stage: string
    readonly projectId: string
    readonly projectVersionId: string
    readonly language: string
    readonly category: string
    readonly created_at: string
    readonly processing_step: string
    readonly waiting_for_completion_of_transcript: boolean
    readonly transcription: boolean
    readonly numberOfStars: number
    readonly belongsToSubtask: boolean
    readonly stlFileUrl: string | null
    readonly hasPaidExportation: boolean
    readonly hasSpeakers: boolean
    readonly isExporting: boolean
    readonly exportedAt: string | null
    readonly exportationFileUrl: string | null
    readonly exportationUrls: IExportationUrls | null
    readonly hasVoice: boolean
    readonly belongsToTtsProject: boolean
  }
}

export interface IExportationUrls {
  readonly [key: string]: string | undefined
}

export interface ISubtitleReference {
  readonly id: string
  readonly type: 'subtitle'
}

export interface IProjectVersionTableRow extends IProjectVersionAttributes {
  readonly id: string
  readonly subtitles: ISubtitleReference[]
}

export interface IFetchProjectVersionsAction {
  readonly type: string
}

export interface IFetchProjectVersionsApiCallPayload {
  readonly selectedLabels: number[]
  readonly page: number
  readonly perPage: number
}

export interface IFetchProjectVersionsFailureAction {
  readonly type: string
}

export interface IFetchProjectVersionsSuccessAction {
  readonly type: string
  readonly projectVersions: IProjectVersion[]
  readonly totalCount: number
  readonly totalPages: number
  readonly subtitles: ISubtitle[]
}

export interface IFetchProjectVersionsSuccessResponse {
  readonly data: IFetchProjectVersionsBody
  readonly config: object
  readonly headers: object
  readonly request: object
  readonly status: 200
  readonly statusText: string
}

export interface IFetchProjectVersionsSuccessAction {
  readonly data: IFetchProjectVersionsBody
  readonly config: object
  readonly headers: object
  readonly request: object
  readonly status: 200
  readonly statusText: string
}

interface IFetchProjectVersionsBody {
  readonly data: IProjectVersion[]
  readonly included: ISubtitle[]
  readonly totalCount: number
  readonly totalPages: number
}

export interface IDeleteProjectVersionPopup {
  readonly projectVersionIds: string[]
}

export interface IOpenDeleteProjectVersionPopupAction {
  readonly type: string
  readonly payload: IDeleteProjectVersionPopup
}

export interface IRateSubtitlePayload {
  readonly comment: string
  readonly subtitleId: string
  readonly numberOfStars: number
}

export type TProjectVersionsListActionTypes =
  TActionTypes |
  IFetchProjectVersionsSuccessAction |
  IOpenDeleteProjectVersionPopupAction |
  IUpdateProjectVersionsAction |
  IUpdateProjectVersionsSuccessAction |
  IOpenExportationAction
